import React, { useState, useEffect } from 'react';
import PetCard from '../components/PetCard';
import PetSearch from '../components/PetSearch';
import { postApiData } from '../functions/netApi';
import './css/pets.css'

const Pets = () => {
  const [pets, setPets] = useState([]);

  const handleSearch = async (params) => {
    console.log('搜索条件:', params);
	try {
		const result = await postApiData("searchPet.php", params);
		setPets(result);
	} catch (error) {
		console.error("Error:", error);
	}
  };

  return (
    <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
		<h2 className='title-text-1 text-blue'>Search</h2>

		<PetSearch onSearch={handleSearch} />
		<div className="pet-list">
			{pets.map(pet => (
			<PetCard key={pet.id} pet={pet} />
			))}
		</div>
    </div>
  );
};

export default Pets;
import React, { useState, useEffect } from 'react';
import { postApiData } from '../functions/netApi';
import Interested from '../components/Interested';
import WhyUs from '../components/WhyUs';
import './css/pets.css';

const Home = () => {

	const [pets, setPets] = useState([]);
	useEffect(() => {
		const fetchPets = async () => {
		  const data = await postApiData("randPet.php", []);
		  setPets(data);
		};
	
		fetchPets();
	  }, []);
	return(
	<div>
		<section style={{paddingBottom: "100px"}} class="section-box flex bg-gray">
			<div class="title-text-1 text-blue">Pet Heaven</div>
			<div style={{marginTop: "50px"}} class="title-text-2 text-blue">Help them find a <span className='text-red'>new home</span></div>
		</section>
		<Interested/>
		<WhyUs/>
		


	</div>);
};

export default Home;
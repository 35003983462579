import React, { useState, useEffect } from 'react';
import { postApiData } from '../functions/netApi';
import PetCard from './PetCard'

const Interested = () => {
	const [pets, setPets] = useState([]);
	useEffect(() => {
		const fetchPets = async () => {
		  const data = await postApiData("randPet.php", []);
		  setPets(data);
		};
	
		fetchPets();
	  }, []);

	return(
		<section class="section-box flex">
			<div class="title-text-1 text-blue">may interested</div>
			<div className="pet-list">
			{pets.map(pet => (
			<PetCard key={pet.id} pet={pet} />
			))}
		</div>
		</section>
	);
}
export default Interested;
import React from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../components/FormInput';

const AdoptPet = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>申请领养宠物</h2>
      <FormInput label="姓名" name="name" type="text" register={register} required />
      <FormInput label="邮箱" name="email" type="email" register={register} required />
      <FormInput label="宠物 ID" name="petId" type="text" register={register} required />
      <button type="submit">提交</button>
    </form>
  );
};

export default AdoptPet;
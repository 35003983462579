import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/petcard.css';



const PetCard = ({ pet }) => {
	const navigate = useNavigate();
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const handleMoreInfo = (id) => {
		navigate(`/pets/${id}`);
	};
	const handleImageLoad = () => {
		setIsImageLoaded(true);
	};

	return(
	<div className="card">
		<input id={'petid'+pet.id} type="radio" name="option" value="1"/>
		<label for={'petid'+pet.id}>
			<div className="card-front">
				{!isImageLoaded && (
						<div className="loading-placeholder" />
					)}
				<img
					className="pet-image"
					src={pet.pic}
					alt={`${pet.name}`}
					onLoad={handleImageLoad}
					style={{ display: isImageLoaded ? 'block' : 'none' }}
				/>
				<div className="title-text-1 text-blue">{pet.name}</div>
			</div>
			<div className="card-back">
				{!isImageLoaded && (
						<div className="loading-placeholder" />
					)}
				<img
					className="pet-image"
					src={pet.pic}
					alt={`${pet.name}`}
					onLoad={handleImageLoad}
					style={{ display: isImageLoaded ? 'block' : 'none', height: "3rem"}}
				/>
				<div className="inner-text-1 text-blue">birth: {pet.birth}</div>
				<div className="inner-text-1 text-blue">sex: {pet.sex}</div>
				<div className="inner-text-1 text-blue">{pet.breed}</div>
				<div className="inner-text-1 text-blue">{pet.info}</div>
				<button className="btn" onClick={() => handleMoreInfo(pet.id)}>More Info</button>
			</div>
		</label>
	</div>);
};

export default PetCard;
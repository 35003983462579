// components/FormInput.js
import React from 'react';

const FormInput = ({ label, name, type, register, required, classN = "" }) => (
  <div className={classN}>
    <label>{label}</label>
    <input {...register(name, { required })} type={type} />
  </div>
);

export default FormInput;
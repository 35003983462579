import React, { useState } from 'react';


const PetSearch = ({ onSearch }) => {
  const [petType, setPetType] = useState('');
  const [ageRange, setAgeRange] = useState({ min: '', max: '' });
  const [gender, setGender] = useState('');
  const [keyword, setKeyword] = useState('');

  const handleSearch = () => {
    const searchParams = {
      petType,
      ageRange,
      gender,
      keyword
    };
    onSearch(searchParams);
  };

  return (
    <div className="pet-search clickable-box">
      <label>
        Type:
        <select value={petType} onChange={(e) => setPetType(e.target.value)}>
          <option value="">All</option>
          <option value="cat">cat</option>
          <option value="dog">dog</option>
          <option value="bird">bird</option>
        </select>
      </label>
      <label>
        age：
        <input 
          type="number" 
          placeholder="min" 
          value={ageRange.min} 
          onChange={(e) => setAgeRange({...ageRange, min: e.target.value})} 
        />
        <input 
          type="number" 
          placeholder="max" 
          value={ageRange.max} 
          onChange={(e) => setAgeRange({...ageRange, max: e.target.value})} 
        />
      </label>
      <label>
        sex:
        <select value={gender} onChange={(e) => setGender(e.target.value)}>
          <option value="">All</option>
          <option value="male">male</option>
          <option value="female">female</option>
        </select>
      </label>
      <label>
        key word:
        <input 
          type="text" 
          placeholder="pls type some thing" 
          value={keyword} 
          onChange={(e) => setKeyword(e.target.value)} 
        />
      </label>
      <button className='btn' style={{display:"inline"}} onClick={handleSearch}>Search</button>
    </div>
  );
};

export default PetSearch;

import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { postApiData } from '../functions/netApi';
import { getCookie } from "../functions/cookie";
import "./css/petinfo.css"

const PetsInfo = () => {
	const { id } = useParams();
	const [pet, setPet] = useState(null);
	const [adoptStatus, setAdoptStatus] = useState(null);

	useEffect(() => {
		const fetchPetData = async () => {
			try {
				const result = await postApiData("getPet.php", { 'id': id });
				setPet(result);
			} catch (error) {
				console.error("Error:", error);
			}
		};
		fetchPetData();
	}, [id]);

	const handleAdopt = async () => {
		const token = getCookie('userToken');
		if (!token) {
		  setAdoptStatus("You must be logged in to adopt a pet.");
		  return;
		}
	
		try {
		  const result = await postApiData("adopt.php", { 'petid': id, 'token': token });
		  if (result.error) {
			setAdoptStatus(result.error);
		  } else {
			setAdoptStatus("Adoption request successfully submitted!");
		  }
		} catch (error) {
		  console.error("Error:", error);
		  setAdoptStatus("There was an error submitting your adoption request.");
		}
	  };


	if (pet === null) {
		return <div>Loading...</div>;
	}

	if (!pet.name) {
		return <div>Pet not found!</div>;
	}

	return (
		<div className="pet-info-container">
			<h2 className="title-text-1 text-blue">{pet.name}</h2>
			<div className="pet-details">
				<img src={pet.pic} alt={`${pet.name}`} className="pet-image-info" />
				<div className="pet-info text-blue">
					<p><strong>Type:</strong> {pet.type.charAt(0).toUpperCase() + pet.type.slice(1)}</p>
					<p><strong>Breed:</strong> {pet.breed}</p>
					<p><strong>Sex:</strong> {pet.sex.charAt(0).toUpperCase() + pet.sex.slice(1)}</p>
					<p><strong>Color:</strong> {pet.colour}</p>
					<p><strong>Birth:</strong> {pet.birth}</p>
					<p><strong>Location:</strong> {pet.location}</p>
					<p><strong>Info:</strong> {pet.info}</p>
					<p><strong>Contact:</strong> <a className="text-red" href={`mailto:${pet.email}`}>{pet.email}</a></p>
				</div>
			</div>
			<button className="btn" onClick={handleAdopt}>Adopt</button>
			{adoptStatus && <div className="title-text-1 text-red adopt-status">{adoptStatus}</div>}
		</div>
	);
};

export default PetsInfo;

import React from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../components/FormInput';
import { getCookie, setCookie} from '../functions/cookie'
import { Link, useNavigate } from 'react-router-dom';
import { postApiData } from '../functions/netApi'

const SignIn = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await postApiData("login.php", data);
      if (response.token) {
        setCookie('userToken', response.token, 31);
        setCookie('userName', response.name, 31);
        navigate('/');
      } else {
        alert("Invalid login credentials");
      }
    } catch (error) {
      console.error("Login failed:", error);
      alert("An error occurred during login");
    }
  };
  
  if(getCookie('userToken') != null){
	return (
		<h2 className='title-text-1 text-blue'>You are already logged in</h2>
	);
  }

  return (
    <form className='text-blue clickable-box' style={{display: "flex", alignItems: "center", flexDirection: "column"}} onSubmit={handleSubmit(onSubmit)}>
      <h2 className='title-text-1 text-blue'>Sign In</h2>
      <FormInput classN='padding-10' label="Email: " name="email" type="email" register={register} required />
      <FormInput classN='padding-10' label="Password: " name="password" type="password" register={register} required />
	  <Link to="/register" className='text-red'>I do't have an account.</Link>
      <button style={{marginTop: '10px'}} className='btn' type="submit">Let's Go</button>
	</form>

  );
};

export default SignIn;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Pets from './pages/Pets';
import Register from './pages/Register';
import SignIn from './pages/SignIn';
import ReleasePet from './pages/ReleasePet';
import AdoptPet from './pages/AdoptPet';
import PetsInfo from './pages/PetsInfo';

const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pets" element={<Pets />} />
	  <Route path="/pets/:id" element={<PetsInfo />} />
      <Route path="/signin" element={<SignIn />} />
	  <Route path="/register" element={<Register />} />
      <Route path="/release-pet" element={<ReleasePet />} />
      <Route path="/adopt-pet" element={<AdoptPet />} />
	  <Route path="*" element={<Home />} />
    </Routes>
	<Footer />
  </Router>
);

export default App;
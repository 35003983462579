import React from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../components/FormInput';
import { getCookie, setCookie} from '../functions/cookie';
import { useNavigate } from 'react-router-dom';
import { postApiData } from '../functions/netApi'

const Register = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
	try {
	  const response = await postApiData("regist.php", data);
	  setCookie('userToken', response.token, 31);
	  setCookie('userName', response.name, 31);
	  navigate('/');
	} catch (error) {
		alert("Registration failed" + error);
	}
  };
  if(getCookie('userToken') != null){
	return (
		<h2 className='title-text-1 text-blue'>You are already logged in</h2>
	);
  }

  return (
    <form className='text-blue clickable-box' style={{display: "flex", alignItems: "center", flexDirection: "column"}} onSubmit={handleSubmit(onSubmit)}>
      <h2 className='title-text-1'>Register as a Member</h2>
      <FormInput classN='padding-10' label="Name: " name="name" type="text" register={register} required />
      <FormInput classN='padding-10' label="Email: " name="email" type="email" register={register} required />
      <FormInput classN='padding-10' label="Password: " name="password" type="password" register={register} required />
      <button style={{marginTop: '10px'}}  className='btn' type="submit">Register</button>
	</form>

  );
};

export default Register;
import React from 'react';

const WhyUs = () => {
  const infoData = [
    {
      title: "Compassionate Care",
      description: "Every animal is treated with love. Our animals receive top-quality medical care, regular socialization, and plenty of affection while waiting for their forever homes."
    },
    {
      title: "Flexible Adoption Process",
      description: "We make adoption simple. Our team works with you to ensure a seamless process, with flexible options for meet-and-greets and home visits to fit your schedule."
    },
    {
      title: "Multiple Adoption Locations",
      description: "Convenient locations throughout the city. Visit our main center or our partner locations to meet adoptable pets in a location that’s best for you."
    },
    {
      title: "Easy Online Application",
      description: "Start your journey online. Complete our secure adoption application from home, saving time and helping us match you with the perfect pet."
    }
  ];

  return (
    <section className="section-box flex  bg-gray">
      <div className="title-text-1 text-blue">WHY US?</div>
      <div className="flex-col">
        {infoData.map((item, index) => (
          <div key={index} className="info-box">
            <h3 className="title-text-2 text-blue">{item.title}</h3>
            <p className="inner-text-1 text-black">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyUs;

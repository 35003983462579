export const setCookie = (name, value, days) => {
	let expires = "";
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
};

export const getCookie = (name) => {
	const nameEQ = name + "=";
	const cookies = document.cookie.split(";");
	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i].trim();
		if (cookie.indexOf(nameEQ) === 0) {
		return decodeURIComponent(cookie.substring(nameEQ.length, cookie.length));
		}
	}
	return null;
};
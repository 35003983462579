

export const postApiData = async (api, data) => {
	const baseUrl = "/api/";
    const apiUrl = baseUrl + api;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error posting data:", error);
        throw error;
    }

}
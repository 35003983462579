import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';

const Footer = () =>(
	<footer class="bg-black text-white">
		<div class="footer-content">
			<div class="logo-box">
				<img src={logo} alt="Pet Heaven logo" class="logo"/>
				<small>© 2024 Pet Heaven</small>
			</div>
			<div class="footer-contact">
				<h4>Contact Us</h4>
				<p>Email: <a href="mailto:support@azoom.com">support@PHeaven.com</a></p>
				<p>Phone: <a href="tel:+123456789">+1 234 567 89</a></p>
				<p>Address: 123 Pet St, love City, State, Zip</p>
			</div>
		</div>
		<a href="https://beian.miit.gov.cn/"> 京ICP备2022008032号-3 </a>
	</footer>
);

export default Footer;
import React from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../components/FormInput';
import { getCookie } from '../functions/cookie';
import { postApiData } from '../functions/netApi';

const ReleasePet = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
	data['token'] = getCookie('userToken');
	postApiData('uploadpet.php',data);
  };
  
  // Check if user is signed in
  if (getCookie('userToken') == null) {
    return (
      <div>
        <h2 className='title-text-1 text-blue'>Please Sign in!</h2>
      </div>
    );
  }

  return (
	<form className='text-blue' onSubmit={handleSubmit(onSubmit)} >
		<h2 className='title-text-1 text-blue'>Release Pet</h2>
		<div className='clickable-box' style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
			<div className='padding-10'>
				<label>Type: </label>
				<select {...register("type", { required: true })} className='form-select'>
					<option value="">Select type</option>
					<option value="dog">Dog</option>
					<option value="cat">Cat</option>
					<option value="bird">Bird</option>
				</select>
			</div>

			<FormInput classN='padding-10' label="Breed: " name="breed" type="text" register={register} required />
			<div className='padding-10'>
			<label>Sex: </label>
			<select {...register("sex", { required: true })} className='form-select'>
				<option value="">Select sex</option>
				<option value="male">Male</option>
				<option value="female">Female</option>
			</select>
			</div>
			<FormInput classN='padding-10' label="Colour: " name="colour" type="text" register={register} required />

			<FormInput classN='padding-10' label="Name: " name="name" type="text" register={register} required />
			<div className='padding-10'>
				<label>Info: </label>
				<textarea  {...register("info")} className='form-textarea' placeholder="Additional information about the pet" />
			</div>
			<FormInput classN='padding-10' label="Picture URL: " name="pic" type="url" register={register} required />

			<FormInput classN='padding-10' label="Birth Date: " name="birth" type="date" register={register} required />

			<FormInput classN='padding-10' label="Location: " name="location" type="text" register={register} required />
		</div>
		<button className='btn' type="submit">Upload</button>
	</form>
  );
};

export default ReleasePet;
